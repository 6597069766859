import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 사용자
        client_user: {
            is_logined: false,
            user_id: "",
            nickname: "",
            email: "",
            profile_image: "",
            type: "",
            status: "",
            created: ""
        },

        // 관리자
        page_admin: {
            is_logined: false,
        },

        mobile_tab: "attacker",

        // 결과값
        final_final: 0,
        final_85: 0,
        final_86: 0,
        final_87: 0,
        final_88: 0,
        final_89: 0,
        final_90: 0,
        final_91: 0,
        final_92: 0,
        final_93: 0,
        final_94: 0,
        final_95: 0,
        final_96: 0,
        final_97: 0,
        final_98: 0,
        final_99: 0,
        final_100: 0,

        is_admin: false,
        is_speed: false,
    },

    getters: {

    },

    mutations: {
        // 사용자 로그인
        login: (state, payload) => {
            state.client_user.is_logined = true
            state.client_user.user_id = payload.user_id
            state.client_user.nickname = payload.nickname
            state.client_user.email = payload.email
            state.client_user.profile_image = payload.profile_image
            state.client_user.type = payload.type
            state.client_user.status = payload.status
            state.client_user.created = payload.created
        },

        // 사용자 로그아웃
        logout: (state) => {
            state.client_user.is_logined = false
            state.client_user.user_id = ""
            state.client_user.nickname = ""
            state.client_user.email = ""
            state.client_user.profile_image = ""
            state.client_user.type = ""
            state.client_user.status = ""
            state.client_user.created = ""
        },

        // 사용자 프로필 수정하기
        update_profile_image: (state, payload) => {
            state.client_user.profile_image = payload
        },

        // 관리자 로그인
        admin_login: (state) => {
            state.page_admin.is_logined = true
        },

        // 관리자 로그아웃
        admin_logout: (state) => {
            state.page_admin.is_logined = false
        },

        // 언어 변경
        language_change: (state, payload) => {
            if(state.language == "kr"){
                state.language = "en"
            }else{
                state.language = "kr"
            }
        },

        // 모바일탭 수정
        update_mobile_tab: (state, payload) => {
            state.mobile_tab = payload
        },

        // 모바일탭 수정
        update_is_admin: (state, payload) => {
            state.is_admin = payload
        },

        // 모바일탭 수정
        update_is_speed: (state, payload) => {
            state.is_speed = payload
        },
    },

    actions: {

    },

    modules: {

    },

    plugins: [
        // 브라우져 꺼져도 유지
        createPersistedState({
            paths: [
                'client_user',
                'page_admin',
                'language'
            ]
        })
    ]
})
